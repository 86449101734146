<template lang="pug">
    .printer
        p.mb12.desc 已绑定{{bindPrinterList.length}}台打印机
        van-cell-group(style="background:none")
            van-cell.mb12(v-for="it in bindPrinterList")
                template(#title)
                    i.op_icon.iconfont.icon-dayinji1
                    span.ml10 {{it.name}}
                template(#right-icon)
                    van-icon.icon(name="delete" @click="unBindUUID(it)")
        FooterButton(:title="'添加打印机'" @save="addPrinter")
        van-popup(v-model="show" position="bottom" :style="{ height: '60%',background:'#f5f5f5' }" closeable @close="closeSearchPrinter")
            p.mt40.mb12.desc 发现{{discoveredBluetoothDevices.length}}个蓝牙设备
            van-cell-group(style="background:none")
                van-cell(v-for="it in discoveredBluetoothDevices" is-link value="点击添加" @click="bindUUID(it)")
                    template(#title)
                        i.op_icon.iconfont.icon-dayinji1
                        span.ml10 {{it.name}}
            FooterButton(:title="searchTitle" @save="searchPrinter")
</template>

<script>
  import FooterButton from '../common/footerButton'
  import bluetooth from '../../bluetooth/pdd'
  import {mapGetters, mapMutations} from "vuex";
  import {Toast} from 'vant';
  import utils from '../../common/utils'

  const bindPrinterListKey = 'bindPrinterList'

  export default {
    computed: {
      ...mapGetters(['discoveredBluetoothDevices', 'currentBluetoothDevices']),
    },
    components: {
      FooterButton
    },
    data() {
      return {
        searchTitle: '正在搜索打印机',
        show: false,
        bindPrinterList: [],
        sdk: window.openSdk
      }
    },
    mounted() {
      let list = utils.get(bindPrinterListKey);
      if (list && list.length > 0) {
        this.bindPrinterList = [...list];
      }
      this.getPrinterList();
    },
    methods: {
      ...mapMutations(['updateDiscoveredBluetoothDevices', 'clearDiscoveredBluetoothDevices', 'clearCurrentBluetoothDevices']),
      closeSearchPrinter(){
        this.clearCurrentBluetoothDevices();
        bluetooth.stopDiscoveryBluetooth();
      },
      addPrinter() {
        this.show = true;
        this.clearDiscoveredBluetoothDevices();
        this.searchPrinter();
      },
      getPrinterList() {
        bluetooth.bluetoothListener('getDevices', (val) => {
          this.updateDiscoveredBluetoothDevices(val);
        })
      },
      searchPrinter() {
        this.clearCurrentBluetoothDevices();
        this.searchTitle = '正在搜索打印机';
        bluetooth.startDiscoveryBluetooth();
        let time = 0;
        let myVar = setInterval(() => {
          if (this.currentBluetoothDevices.length > 0 || time > 9) {
            this.searchTitle = '重新搜索打印机';
            clearInterval(myVar);
            bluetooth.stopDiscoveryBluetooth();
          }
          time++;
        }, 1000)
      },
      bindUUID(it) {
        let selected = this.bindPrinterList.filter(i => it.uuid === i.uuid);
        if (selected && selected.length > 0) {
          Toast.fail('打印机已添加');
          return;
        }
        this.bindPrinterList.push(it);
        utils.set(bindPrinterListKey, this.bindPrinterList);
        Toast.success('添加成功');
      },
      unBindUUID(it) {
        this.bindPrinterList = this.bindPrinterList.filter(i => it.uuid !== i.uuid);
        utils.set(bindPrinterListKey, this.bindPrinterList);
      }
    }
  }
</script>

<style lang="stylus">
    .printer
        padding 12px
        .desc
            font-size 13px
            color #ccc
            text-align center
        .icon
            color: $active
            font-size 16px
            line-height 25px
        .van-cell__value
            font-size 12px

</style>
