<template lang="pug">
    .footer-button
        van-button.van-button(type="primary" @click="$emit('save')") {{title}}
</template>

<script>
    export default {
      props: ['title'],
    }
</script>

<style lang="stylus">
.footer-button
    position: fixed;
    bottom: -5px;
    left: 0;
    z-index: 999;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 16px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    .van-button
        border-radius: 999px;
        margin-bottom: 12px;
        padding: 0 15px;
        font-size: 14px;
        height: 44px;
        width: 100%;
</style>
